import type { TotalCount } from "~/types";
// ssrでは外側にuseFetchを置けない

// 取得状態付データ
type StateObject<T> = {
  status: "idle" | "pending" | "success" | "error";
  data: T;
};

export default async function useTotalCount() {
  // 共有状態データ
  const total = useState<StateObject<TotalCount>>("totalCount", () => {
    return { status: "idle", data: { sekou: 0, review: 0, plan: 0 } };
  });

  // 取得中でない&空の値があるときに取得
  if (
    total.value.status != "pending" &&
    (!total.value.data.sekou ||
      !total.value.data.review ||
      !total.value.data.plan)
  ) {
    try {
      // 同時実行で多重取得防止のためにstatusを変える
      total.value.status = "pending";
      const res = await $fetch("/api/totals/count");
      if (res) {
        // この時点で端数を丸める
        total.value.data = {
          sekou: roundCount(res.sekou),
          plan: roundCount(res.plan),
          review: roundCount(res.review),
        };
        total.value.status = "success";
      }
    } catch (e) {
      total.value.status = "error";
      console.error(e);
    }
  }

  return { data: total.value.data };
}
